import { mkenum } from "../../utils/mkenum";

export const UserProfileType = mkenum({
  business: "business",
  personal: "personal",
});

export type UserProfileType =
  (typeof UserProfileType)[keyof typeof UserProfileType];

export const userProfileTypes = Object.keys(
  UserProfileType,
) as UserProfileType[];
