/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/function-component-definition */
import {
  Accordion,
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { skipToken } from "@reduxjs/toolkit/query";
import { workingToDisplayUnits } from "@s2z-platform/functions";
import { FormattedDate, FormattedNumber } from "react-intl";
import { Fragment } from "react";
import { EvidenceFeatureEnum } from "@s2z-platform/constants";
import {
  useChangeUserProfileTypeMutation,
  useGetUserInfoQuery,
  useLazyGetEvidenceEntrySignedUrlQuery,
  useUpdateEstimateStatusMutation,
} from "../../services/userApi";
import { calculatedFootprintCopy } from "./calculatedFootprintCopy";

type UserModalProps = {
  uuid: string | undefined;
  show: boolean;
  onClose: () => void;
};

export function UserModal({ uuid, onClose, show }: UserModalProps) {
  const { data: user, isFetching } = useGetUserInfoQuery(uuid ?? skipToken);

  const [changeUserProfileType] = useChangeUserProfileTypeMutation();

  const [updateEstimateStatus] = useUpdateEstimateStatusMutation();

  const [getEvidenceEntrySignedUrl] = useLazyGetEvidenceEntrySignedUrlQuery();

  async function downloadEvidence(
    userUuid: string,
    feature: EvidenceFeatureEnum,
    evidenceUuid: string
  ) {
    const res = await getEvidenceEntrySignedUrl({
      userUuid,
      feature,
      evidenceUuid,
    });

    if ("data" in res && res.data) {
      window.open(res.data.signedUrl, "_blank")?.focus();
    } else {
      console.error(res.error);
    }
  }

  return (
    <Modal size="xl" show={show} onHide={onClose} id="user-info-modal">
      <Modal.Body>
        {user && !isFetching ? (
          <div>
            {/* if only there were some kind of Modal Header component... Oh well, too late to check now. */}
            <div id="modal-header">
              <div>
                <h2>{`${user.firstname} ${user.lastname}${
                  user.companyName ? ` (${user.companyName})` : ""
                }`}</h2>
                <h3>{`User ${user.uuid}`}</h3>
              </div>
              <button
                type="button"
                className="btn-nostyle"
                id="close-btn"
                onClick={onClose}
              >
                <img
                  alt="close button"
                  src="/images/close.svg"
                  width={20}
                  height={20}
                />
              </button>
            </div>
            <Accordion alwaysOpen defaultActiveKey={["intents", "userinfo"]}>
              <Accordion.Item eventKey="userinfo">
                <Accordion.Header>
                  <h4>User Info</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <table>
                    <tbody>
                      <tr>
                        <td>Email</td>
                        <td>{user.email}</td>
                      </tr>
                      <tr>
                        <td>Profile type</td>
                        <td>
                          <Stack direction="horizontal">
                            {user.profileType}
                            <Button
                              type="button"
                              className="btn-nostyle p-0 ms-1 text-primary"
                              style={{ fontSize: 18, lineHeight: "5px" }}
                              onClick={() => {
                                changeUserProfileType({
                                  uuid: user.uuid,
                                  profileType:
                                    user.profileType === "personal"
                                      ? "business"
                                      : "personal",
                                });
                              }}
                              disabled={
                                (user.impactIntents &&
                                  user.impactIntents.length > 0) ||
                                (user.estimates && user.estimates.length > 0) ||
                                (user.vehicles && user.vehicles.length > 0) ||
                                (user.widgets && user.widgets.length > 0)
                              }
                            >
                              ⟳
                            </Button>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  Click the button to toggle the user&apos;s
                                  profile type. Note that changing from business
                                  to personal will delete their company name.
                                  Profile type toggling is not supported when
                                  the user has any profile-type-specific
                                  features such as impact intents, estimates,
                                  vehicles or widgets.
                                </Tooltip>
                              }
                            >
                              <Stack direction="horizontal" className="px-1">
                                <img
                                  alt="info"
                                  src="/images/info.svg"
                                  width={12}
                                  height={12}
                                />
                              </Stack>
                            </OverlayTrigger>
                          </Stack>
                        </td>
                      </tr>
                      <tr>
                        <td>Registration date (UTC)</td>
                        <td>{new Date(user.createdAt).toISOString()}</td>
                      </tr>
                      <tr>
                        <td>Default currency</td>
                        <td>{user.defaultCurrency}</td>
                      </tr>
                      <tr>
                        <td>Stripe customer ID</td>
                        <td>
                          <a
                            href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                          >
                            {user.stripeCustomerId}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Medusa customer ID</td>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_MEDUSA_URL}/console/a/customers/${user.medusaCustomerId}`}
                          >
                            {user.medusaCustomerId}
                          </a>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>HubSpot contact ID</td>
                        <td>
                          {user.hubspotData?.contactId && (
                            <a
                              href={`https://app-eu1.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/contact/${user.hubspotData.contactId}`}
                            >
                              {user.hubspotData.contactId}
                            </a>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>HubSpot company ID</td>
                        <td>
                          {user.hubspotData?.companyId && (
                            <a
                              href={`https://app-eu1.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/company/${user.hubspotData.companyId}`}
                            >
                              {user.hubspotData.companyId}
                            </a>
                          )}
                        </td>
                      </tr> */}
                      <tr>
                        <td>Mobile number</td>
                        <td>{user.mobileNumber}</td>
                      </tr>
                      <tr>
                        <td>VAT number</td>
                        <td>{user.vatNumber}</td>
                      </tr>
                      <tr>
                        <td>Website</td>
                        <td>{user.website}</td>
                      </tr>
                      <tr>
                        <td>Logo</td>
                        <td>
                          <a href={user.brandLogoUrl}>{user.brandLogoUrl}</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>
                          {user.address &&
                            (
                              [
                                "addressLine1",
                                "addressLine2",
                                "city",
                                "postCode",
                                "country",
                              ] as const
                            ).map((name) => (
                              <p key={name}>{user.address[name]}</p>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="intents">
                <Accordion.Header>
                  <h4>Impact intents ({user.impactIntents.length})</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {user.impactIntents && user.impactIntents.length > 0
                    ? [...user.impactIntents]
                        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                        .map((intent) => (
                          <div key={intent.uuid} className="intent-info">
                            <h6>{intent.uuid}</h6>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Product(s)</td>
                                  <td>
                                    {intent.customerProductConfigs
                                      .map((c) => c.product.name)
                                      .join(", ")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Interval</td>
                                  <td>{intent.interval}</td>
                                </tr>
                                {intent.interval !== "one_time" && (
                                  <tr>
                                    <td>Subscription status</td>
                                    <td>{intent.subscriptionStatus}</td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Payment status</td>
                                  <td>{intent.intentStatus}</td>
                                </tr>
                                <tr>
                                  <td>Creation date (UTC)</td>
                                  <td>
                                    {new Date(intent.createdAt).toISOString()}
                                  </td>
                                </tr>
                                {intent.customerProductConfigs.some(
                                  (c) =>
                                    c.product.identifier ===
                                    "business-ecommerce-recurring"
                                ) && (
                                  <tr>
                                    <td>Connected store(s)</td>
                                    <td>
                                      {intent.customerProductConfigs
                                        .filter(
                                          (c) =>
                                            c.product.identifier ===
                                            "business-ecommerce-recurring"
                                        )
                                        .map((c) =>
                                          c.store
                                            ? `${c.store.storeName} (${c.store.platform})`
                                            : "none"
                                        )}
                                    </td>
                                  </tr>
                                )}
                                {intent.stripeSubscriptionId && (
                                  <tr>
                                    <td>Stripe subscription ID</td>
                                    <td>
                                      <a
                                        href={`https://dashboard.stripe.com/subscriptions/${intent.stripeSubscriptionId}`}
                                      >
                                        {intent.stripeSubscriptionId}
                                      </a>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>Stripe intent ID</td>
                                  <td>
                                    {intent.stripeSetupIntentId ? (
                                      <a
                                        href={`https://dashboard.stripe.com/setup_intents/${intent.stripeSetupIntentId}`}
                                      >
                                        {intent.stripeSetupIntentId}
                                      </a>
                                    ) : (
                                      <a
                                        href={`https://dashboard.stripe.com/payments/${intent.stripePaymentIntentId}`}
                                      >
                                        {intent.stripePaymentIntentId}
                                      </a>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Payment(s)</td>
                                  <td>
                                    {intent.payments
                                      ? [...intent.payments]
                                          ?.sort(
                                            (a, b) =>
                                              new Date(b.createdAt).getTime() -
                                              new Date(a.createdAt).getTime()
                                          )
                                          .map((p) => (
                                            <Fragment key={p.uuid}>
                                              <div>
                                                {p.uuid} (
                                                <FormattedDate
                                                  value={new Date(p.createdAt)}
                                                />
                                                )
                                              </div>
                                              <ul className="small-list">
                                                <li>Status: {p.status}</li>
                                                <li>
                                                  Amount:{" "}
                                                  {p.currency && (
                                                    <FormattedNumber
                                                      style="currency"
                                                      value={workingToDisplayUnits(
                                                        p.currency.code,
                                                        p.orderTotal
                                                      )}
                                                      currency={
                                                        p.currency?.code
                                                      }
                                                    />
                                                  )}
                                                </li>
                                                <li>
                                                  Date (UTC):{" "}
                                                  {new Date(
                                                    p.createdAt
                                                  ).toISOString()}
                                                </li>
                                                <li>
                                                  Stripe invoice ID:{" "}
                                                  <a
                                                    href={`https://dashboard.stripe.com/invoices/${p.stripeInvoiceId}`}
                                                  >
                                                    {p.stripeInvoiceId}
                                                  </a>
                                                </li>
                                                <li>
                                                  Medusa order ID(s):{" "}
                                                  {p.paymentConfigReferences?.map(
                                                    (c) => (
                                                      <a
                                                        href={`${process.env.REACT_APP_MEDUSA_URL}/console/a/orders/${c.medusaOrderId}`}
                                                      >
                                                        {c.medusaOrderId}
                                                      </a>
                                                    )
                                                  )}
                                                </li>
                                                {/* {p.hubspotData?.dealId ? (
                                                  <li>
                                                    Deal ID:{" "}
                                                    {p.hubspotData && (
                                                      <a
                                                        href={`https://app-eu1.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/deals/${p.hubspotData.dealId}`}
                                                      >
                                                        {p.hubspotData?.dealId}
                                                      </a>
                                                    )}
                                                  </li>
                                                ) : null} */}
                                              </ul>
                                            </Fragment>
                                          ))
                                      : null}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))
                    : "(none)"}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="logins">
                <Accordion.Header>
                  <h4>Logins ({user.userLoginAudits.length})</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {user.userLoginAudits && user.userLoginAudits.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>IP</th>
                          <th>Identity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...user.userLoginAudits]
                          .sort(
                            (a, b) =>
                              new Date(b.loginAt).getTime() -
                              new Date(a.loginAt).getTime()
                          )
                          .map((a) => (
                            <tr>
                              <td>
                                <FormattedDate value={new Date(a.loginAt)} />
                              </td>
                              <td>{a.loginIp}</td>
                              <td>{a.loginWith}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    "(none)"
                  )}
                </Accordion.Body>
              </Accordion.Item>
              {user.profileType === "business" && (
                <Accordion.Item eventKey="estimates">
                  <Accordion.Header>
                    <h4>Estimates ({user.estimates.length})</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {user.estimates && user.estimates.length > 0
                      ? [...user.estimates]
                          .sort((a, b) =>
                            a.effectiveDate < b.effectiveDate ? 1 : -1
                          )
                          .map((e) => (
                            <div key={e.uuid}>
                              <h6>{e.uuid}</h6>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Industry benchmark</td>
                                    <td>
                                      <FormattedNumber
                                        value={e.co2eEstimateKg}
                                      />{" "}
                                      Kg CO₂e per month (
                                      <FormattedNumber
                                        style="percent"
                                        minimumSignificantDigits={2}
                                        value={e.scopeOneProportion}
                                      />{" "}
                                      S1,{" "}
                                      <FormattedNumber
                                        style="percent"
                                        minimumSignificantDigits={2}
                                        value={e.scopeTwoProportion}
                                      />{" "}
                                      S2,{" "}
                                      <FormattedNumber
                                        style="percent"
                                        minimumSignificantDigits={2}
                                        value={e.scopeThreeProportion}
                                      />{" "}
                                      S3)
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Effective date</td>
                                    <td>{e.effectiveDate}</td>
                                  </tr>
                                  <tr>
                                    <td>Status</td>
                                    <td>
                                      {calculatedFootprintCopy[e.status]}{" "}
                                      {e.status !== "estimated" ? (
                                        <button
                                          type="button"
                                          className="ms-2"
                                          onClick={() =>
                                            updateEstimateStatus({
                                              userUuid: user.uuid,
                                              estimateUuid: e.uuid,
                                              estimateStatus: (
                                                [
                                                  "declared",
                                                  "verified",
                                                ] as const
                                              ).filter(
                                                (s) => s !== e.status
                                              )[0],
                                            })
                                          }
                                        >
                                          {e.status === "verified"
                                            ? "Un-verify"
                                            : "Verify"}
                                        </button>
                                      ) : null}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>User data</td>
                                    <td>
                                      <pre>
                                        {JSON.stringify(
                                          e.userProvidedValues,
                                          undefined,
                                          2
                                        )}
                                      </pre>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Creation date</td>
                                    <td>
                                      {new Date(e.createdAt).toISOString()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Update date</td>
                                    <td>
                                      {new Date(e.updatedAt).toISOString()}
                                    </td>
                                  </tr>
                                  {"calculatedFootprint" in e &&
                                  e.calculatedFootprint ? (
                                    <tr>
                                      <td>Calculated footprint</td>
                                      <td>
                                        <Accordion alwaysOpen>
                                          <Accordion.Item
                                            eventKey={`${e.uuid}-calculationInformation`}
                                          >
                                            <Accordion.Header>
                                              Calculation Data
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <Stack>
                                                {(
                                                  [
                                                    "scopeOne",
                                                    "scopeTwo",
                                                    "scopeThree",
                                                  ] as const
                                                ).map((name) => (
                                                  <div>
                                                    <b>
                                                      {
                                                        calculatedFootprintCopy[
                                                          name
                                                        ]
                                                      }
                                                      :
                                                    </b>{" "}
                                                    <FormattedNumber
                                                      value={
                                                        Number(
                                                          e.calculatedFootprint![
                                                            `${name}Co2eKg`
                                                          ]
                                                        ) / 1000
                                                      }
                                                    />{" "}
                                                    tonnes
                                                  </div>
                                                ))}
                                                <div className="mt-2">
                                                  <b>Inventory year:</b>{" "}
                                                  {new Date(
                                                    e.calculatedFootprint.inventoryYear
                                                  ).getFullYear()}
                                                </div>
                                                <div className="mb-2">
                                                  <b>Consolidation approach:</b>{" "}
                                                  {
                                                    calculatedFootprintCopy[
                                                      e.calculatedFootprint
                                                        .consolidationApproach
                                                    ]
                                                  }
                                                </div>
                                                {(
                                                  [
                                                    "scopeOneCategories",
                                                    "scopeTwoCategories",
                                                    "scopeThreeCategories",
                                                  ] as const
                                                ).map((name) => (
                                                  <div>
                                                    <b>
                                                      {
                                                        calculatedFootprintCopy[
                                                          name
                                                        ]
                                                      }
                                                      :
                                                    </b>{" "}
                                                    {e.calculatedFootprint![
                                                      name
                                                    ].length > 0 ? (
                                                      <ul className="m-0">
                                                        {e.calculatedFootprint![
                                                          name
                                                        ].map((e) => (
                                                          <li>
                                                            {
                                                              calculatedFootprintCopy[
                                                                e
                                                              ]
                                                            }
                                                          </li>
                                                        ))}
                                                      </ul>
                                                    ) : (
                                                      "<none>"
                                                    )}
                                                  </div>
                                                ))}
                                              </Stack>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item
                                            eventKey={`${e.uuid}-evidence`}
                                          >
                                            <Accordion.Header>
                                              Evidence
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              {e.calculatedFootprint
                                                .evidenceEntryRefs.length >
                                              0 ? (
                                                <table>
                                                  <tr>
                                                    <th>File name</th>
                                                    <th>Status</th>
                                                    <th>Download</th>
                                                    <th>UUID</th>
                                                  </tr>
                                                  {e.calculatedFootprint.evidenceEntryRefs.map(
                                                    (r) => (
                                                      <tr key={r.uuid}>
                                                        <td>
                                                          {
                                                            r.evidenceEntry
                                                              .fileName
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            r.evidenceEntry
                                                              .status
                                                          }
                                                        </td>
                                                        <td>
                                                          <button
                                                            type="button"
                                                            onClick={() =>
                                                              downloadEvidence(
                                                                user.uuid,
                                                                "footprint",
                                                                r.evidenceEntry
                                                                  .uuid
                                                              )
                                                            }
                                                          >
                                                            Download
                                                          </button>
                                                        </td>
                                                        <td>
                                                          {r.evidenceEntry.uuid}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </table>
                                              ) : (
                                                "<none>"
                                              )}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          ))
                      : "(none)"}
                  </Accordion.Body>
                </Accordion.Item>
              )}
              <Accordion.Item eventKey="vehicles">
                <Accordion.Header>
                  <h4>Vehicles ({user.vehicles.length})</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {user.vehicles && user.vehicles.length > 0 ? (
                    <Accordion>
                      {user.vehicles.map((v) => (
                        <Accordion.Item key={v.uuid} eventKey={v.uuid}>
                          <Accordion.Header>
                            {v.uuid}
                            {v.registrationNumber
                              ? ` (${v.registrationNumber})`
                              : null}
                          </Accordion.Header>
                          <Accordion.Body>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Registration number</td>
                                  <td>{v.registrationNumber}</td>
                                </tr>
                                <tr>
                                  <td>Country of registration</td>
                                  <td>{v.countryOfRegistration}</td>
                                </tr>
                                <tr>
                                  <td>Annual mileage</td>
                                  <td>
                                    {v.annualMileage} {v.annualMileageUnit}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Grammes CO₂e per kilometre</td>
                                  <td>{v.gCo2ePerKm}</td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td>{v.status}</td>
                                </tr>
                                <tr>
                                  <td>Vehicle data</td>
                                  <td>
                                    <pre>
                                      {JSON.stringify(
                                        { ...v.vehicleData, note: undefined },
                                        undefined,
                                        2
                                      )}
                                    </pre>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mileage records</td>
                                  <td>
                                    {v.mileageRecords &&
                                    v.mileageRecords.length > 0
                                      ? v.mileageRecords.map((r) => (
                                          <div key={r.uuid}>
                                            {r.uuid}
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>Start date</td>
                                                  <td>
                                                    {r.effectiveStartDate}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>End date</td>
                                                  <td>{r.effectiveEndDate}</td>
                                                </tr>
                                                <tr>
                                                  <td>Mileage</td>
                                                  <td>
                                                    {parseInt(
                                                      r.quantity as unknown as string,
                                                      10
                                                    )}{" "}
                                                    {r.units}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    Associated impact intent
                                                  </td>
                                                  <td>{r.impactIntent.uuid}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        ))
                                      : "(none)"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Creation date</td>
                                  <td>
                                    <FormattedDate
                                      value={new Date(v.createdAt)}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  ) : (
                    "(none)"
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : (
          <div id="user-modal-spinner-box">
            <Spinner />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
