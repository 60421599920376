import { createApi } from "@reduxjs/toolkit/query/react";
import { ListUsersDto } from "@s2z-platform/dto";
import { ListResponse } from "@s2z-platform/types";
import {
  EstimateEntity,
  ProductEntity,
  UserEntity,
} from "@s2z-platform/entity";
import {
  EstimateStatusEnum,
  EvidenceFeatureEnum,
} from "@s2z-platform/constants";
import { buildBaseQuery } from "./functions";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: buildBaseQuery(),
  tagTypes: ["USERS", "CURRENT_USER"],
  endpoints: (builder) => ({
    getProducts: builder.query<ProductEntity[], null>({
      query: () => "/api/admin/products",
    }),
    getUsers: builder.query<ListResponse<UserEntity>, ListUsersDto>({
      query: (dto) => ({
        url: "/api/admin/users",
        params: {
          ...dto,
          productIdentifiers:
            dto.productIdentifiers && dto.productIdentifiers.length > 0
              ? dto.productIdentifiers?.join(",")
              : undefined,
          storePlatforms:
            dto.storePlatforms && dto.storePlatforms.length > 0
              ? dto.storePlatforms?.join(",")
              : undefined,
          searchQuery:
            dto.searchQuery?.length && dto.searchQuery?.length > 0
              ? dto.searchQuery
              : undefined,
        },
      }),
      providesTags: ["USERS"],
    }),
    getUserInfo: builder.query<UserEntity, string>({
      query: (uuid) => ({
        url: `/api/admin/users/${uuid}`,
      }),
      providesTags: ["USERS", "CURRENT_USER"],
    }),
    changeUserProfileType: builder.mutation<
      UserEntity,
      { uuid: string; profileType: "business" | "personal" }
    >({
      query: ({ uuid, profileType }) => ({
        method: "PATCH",
        url: `/api/admin/users/${uuid}/profile-type`,
        body: { profileType },
      }),
      invalidatesTags: ["USERS", "CURRENT_USER"],
    }),
    updateEstimateStatus: builder.mutation<
      EstimateEntity,
      {
        userUuid: string;
        estimateUuid: string;
        estimateStatus: EstimateStatusEnum;
      }
    >({
      query: ({ userUuid, estimateUuid, estimateStatus }) => ({
        method: "PATCH",
        url: `/api/admin/users/${userUuid}/estimates/${estimateUuid}/status`,
        body: {
          estimateStatus,
        },
      }),
      invalidatesTags: ["CURRENT_USER"],
    }),
    getEvidenceEntrySignedUrl: builder.query<
      { signedUrl: string },
      { userUuid: string; feature: EvidenceFeatureEnum; evidenceUuid: string }
    >({
      query: ({ userUuid, feature, evidenceUuid }) => ({
        method: "GET",
        url: `/api/admin/users/${userUuid}/evidence/${feature}/${evidenceUuid}`,
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetUsersQuery,
  useGetUserInfoQuery,
  useChangeUserProfileTypeMutation,
  useUpdateEstimateStatusMutation,
  useLazyGetEvidenceEntrySignedUrlQuery,
} = userApi;
