import { mkenum } from "../utils/mkenum";

export const EvidenceFeatureEnum = mkenum({
  footprint: "footprint",
});

export type EvidenceFeatureEnum =
  (typeof EvidenceFeatureEnum)[keyof typeof EvidenceFeatureEnum];

export const EvidenceStatusEnum = mkenum({
  notUploaded: "notUploaded",
  uploading: "uploading",
  complete: "complete",
});

export type EvidenceStatusEnum =
  (typeof EvidenceStatusEnum)[keyof typeof EvidenceStatusEnum];

export const EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS = {
  "image/jpeg": ["jpeg", "jpg"],
  "image/webp": ["webp"],
  "image/png": ["png"],
  "application/pdf": ["pdf"],
  "application/msword": ["doc"],
  "application/vnd.ms-powerpoint": ["ppt"],
  "application/vnd.ms-excel": ["xls"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    "docx",
  ],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    "pptx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ["xlsx"],
  "application/vnd.oasis.opendocument.presentation": ["odp"],
  "application/vnd.oasis.opendocument.spreadsheet": ["ods"],
  "application/vnd.oasis.opendocument.text": ["odt"],
} as const;

const supportedMimeTypes = Object.keys(
  EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS,
) as (keyof typeof EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS)[];

const supportedFileExtensions = Object.values(
  EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS,
).flat() as (typeof EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS)[keyof typeof EVIDENCE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS][number][];

export const EvidenceMimeTypeEnum = mkenum(
  Object.fromEntries(supportedMimeTypes.map((v) => [v, v])),
);
export type EvidenceMimeTypeEnum =
  (typeof EvidenceMimeTypeEnum)[keyof typeof EvidenceMimeTypeEnum];

export const EvidenceFileTypeEnum = mkenum(
  Object.fromEntries(supportedFileExtensions.map((v) => [v, v])),
);
export type EvidenceFileTypeEnum =
  (typeof EvidenceFileTypeEnum)[keyof typeof EvidenceFileTypeEnum];
