import {
  EvidenceFileTypeEnum,
  EvidenceMimeTypeEnum,
} from "@s2z-platform/constants";

export function checkFileName(value: string) {
  return (Object.keys(EvidenceFileTypeEnum) as EvidenceFileTypeEnum[]).some(
    (type) => value.toLowerCase().endsWith(`.${type}`),
  );
}

export function checkMimeType(value: string) {
  return (Object.keys(EvidenceMimeTypeEnum) as EvidenceMimeTypeEnum[]).some(
    (acceptedMimeType) => value === acceptedMimeType,
  );
}
